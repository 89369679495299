import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CellTitleHead } from "../../../components/CellTitle";
import HeadWrapper from "../../../components/HeadWrapper";
import ReturnBack from "../../../components/ReturnBack";
import imgNewsBg from "../../../assets/news/img_news--bg.png";
import { httpGet } from "../../../services/request";
import "./index.scss";

function NewsDetail() {
  const params = useParams();
  const [data, setData] = useState<any>();
  useEffect(() => {
    httpGet("/api-cms/open/gw_open/home_page_news_info", params).then((res) => {
      if (res?.code?.toString() === "0") {
        let t = res.data.content;
        // res.data.content = t.replace(
        //   /(<img.*?src=")(.*?)"(.*?>)/g,
        //   (r: any, $1: any, $2: any, $3: any) => {
        //     return (
        //       $1 +
        //       `https://images.weserv.nl/?url=${$2}"` +
        //       " referrer-policy='no-referrer' " +
        //       $3
        //     );
        //   }
        // );
        setData(res.data);
      }
    });
  }, []);
  return (
    <div className="news-detail">
      <HeadWrapper
        title={
          <CellTitleHead
            title="新闻中心"
            english="NEWS CENTER"
            className="d-flex align-items-center justify-content-center news-page-content"
          />
        }
        imgUrl={imgNewsBg}
      ></HeadWrapper>
      <div className="news-detail-content">
        <div
          className="text-start"
          id="test"
          dangerouslySetInnerHTML={{ __html: data?.content ?? "" }}
        />
      </div>
      <ReturnBack />
    </div>
  );
}

export default NewsDetail;
