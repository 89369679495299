import CellTitle, { CellTitleLine } from "../../components/CellTitle";
import "./index.scss";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import classNames from "classnames";
const Title = (props: any) => {
  const { data } = props;
  return (
    <div className="solution-page-card__title">
      <div>
        <span>{data?.index}</span>
        <h3>{data?.title}</h3>
      </div>
      <p className="mt-1 mb-4">{data?.content}</p>
    </div>
  );
};
const CardItem = (props: { content?: string; url: string ,children?:any}) => {
  const { content, url,children } = props;
  return (
    <div className="solution-page-card__item flex-column d-flex align-items-center">
      <img src={url} alt="" className="mb-3 d-block" />
      {content && <div>{content}</div>}
      {children}
    </div>
  );
};
const SwiperArr = (props: {
  dataArr: { url: any; content?: string }[];
  isSwiper?: boolean;
}) => {
  const { dataArr, isSwiper } = props;
  return (
    <>
      <div className={classNames(["d-md-block", isSwiper && "d-none"])}>
        <div className="solution-page-card__content d-flex">
          {dataArr?.map((item: any,index:number) => (
            <CardItem key={index} url={item?.url} content={item?.content} />
          ))}
        </div>
      </div>
      {isSwiper && (
        <div className="solution-page-card__content d-md-none">
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {dataArr?.map((item: any,index:number) => (
              <SwiperSlide key={index}>
                <CardItem url={item?.url} content={item?.content} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};
function Solution() {
  return (
    <div className="solution-page">
      <div className="solution-page-content m-page-t pt-3">
        <div className="d-flex justify-content-center px-3 px-md-0">
          <CellTitleLine
            title="鲸充全栈式智能充电解决方案"
            lineDirection="right"
            content={
              <div className="solution-page-solution__content my-3">
                目前公共充电设施总量不足，现有设施利用率又比较低，经常呈现出“有车无桩”和“有桩无车”的尴尬局面。为解决行业痛点，鲸充不断探索，升
                级车位级充电运营解决方案，以行业领先思维，提供更便捷、更智能、更高效的全栈式“停车+充电+配套+运营”定制化服务，为车主提供便捷，
                让客户实现营收，让新能源汽车充电不再难！
              </div>
            }
          />
        </div>
        <img
          src="/image/solution/img_solution_pc.png"
          className="d-none d-md-block solution-page-solution__img"
          alt=""
        />
        <img
          src="/image/solution/img_solution_app.png"
          className="d-block d-md-none solution-page-solution__img"
          alt=""
        />
        <div className="solution-page-solution__description">
          <div className="solution-page-solution__description-block mt-3">
            从「规划建站」——「落地运营」全程指导
          </div>
          <div className="solution-page-solution__description-font">鲸充将为您提供更加完善更加优质的定制化服务</div>
        </div>
      </div>
      <div className="solution-page-card solution-page-card1  pt-4">
        <Title
          data={{
            index: "01",
            title: "品牌价值赋能",
            content:
              "定制化品牌形象打造，全渠道营销活动及媒介推广，精准触达高效转化。",
          }}
        />
        <Row className="solution-page-card__content">
          <Col
            md={6}
            className="background-img mb-2"
            style={{
              backgroundImage: `url(/image/solution/img_solution-1.png)`,
            }}
          />
          <Col md={6} className="d-flex justify-content-between mb-2 brand-card">
            <CardItem
              url="/image/solution/img_solution-2.png"
              content="定制场站VI视觉全案设计方案"
            />
            <CardItem
              url="/image/solution/img_solution-3.png"
              content="品牌策略规划凝练差异化服务"
            />
            <CardItem
              url="/image/solution/img_solution-4.png"
              content="全域品牌营销精准触达转化"
            />
          </Col>
        </Row>
      </div>
      <div className="solution-page-card solution-page-card2 pt-4">
        <Title
          data={{
            index: "02",
            title: "专业运营赋能",
            content: "行业领先思维，多年实战经验赋能投建-运营全流程落地",
          }}
        />
        <SwiperArr
          dataArr={[
            { url: "/image/solution/img_solution-5.png" },
            { url: "/image/solution/img_solution-6.png" },
            { url: "/image/solution/img_solution-7.png" },
            { url: "/image/solution/img_solution-8.png" },
            { url: "/image/solution/img_solution-9.png" },
            { url: "/image/solution/img_solution-10.png" },
          ]}
        />
      </div>
      <div className="solution-page-card solution-page-card3 pt-4">
        <Title
          data={{
            index: "03",
            title: "技术赋能",
            content: "创新技术赋能场站运营，为车主提供高质量的充电体验",
          }}
        />
        <SwiperArr
          dataArr={[
            {
              url: "/image/solution/img_solution-11.png",
              content: "柔性充电动态分配",
            },
            {
              url: "/image/solution/img_solution-12.png",
              content: "即插即充远程OTA升级",
            },
            {
              url: "/image/solution/img_solution-13.png",
              content: "液冷超充极致体验",
            },
            {
              url: "/image/solution/img_solution-14.png",
              content: "可视化大数据系统平台",
            },
            {
              url: "/image/solution/img_solution-15.png",
              content: "自有App及小程序",
            },
          ]}
          isSwiper={true}
        />
      </div>
      <div className="solution-page-card solution-page-card4 pt-4">
        <Title
          data={{
            index: "04",
            title: "服务赋能",
            content: "始终为客户多想一点，成就客户",
          }}
        />
        <div className="solution-page-card__content  d-flex">
          <CardItem url="/image/solution/img_solution-16.png" />
          <CardItem url="/image/solution/img_solution-17.png" />
          <CardItem url="/image/solution/img_solution-18.png">
            <img src="/image/solution/img_solution-19.png" className="customer-img d-none d-md-block" />
          </CardItem>
        </div>
      </div>
    </div>
  );
}
export default Solution;
