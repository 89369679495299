import { useState, useEffect } from 'react';
import { CellTitleHead } from "../../components/CellTitle";
import Tab from "../../components/Tab";
import CarouselContent from '../Home/CarouselContent';
import HeadWrapper from '../../components/HeadWrapper';
import { httpPost } from "../../services/request";
import imgNewsBg from "../../assets/news/img_news--bg.png";
import { viewPortWidth } from '../../utils/util';
import "./index.scss";

type DataItem = {
    isLoaded?: boolean;
    data?: any[];
    page?: number;
}

async function fetchData(params: any) {
    return httpPost("/api-cms/open/gw_open/home_page_news_list", params)
}

function News() {
    const [tabKey, setTabKey] = useState(0);
    const [data, setData] = useState<[DataItem, DataItem, DataItem, DataItem]>([{}, {}, {}, {}]);
    useEffect(() => {
        fetchDataContent({ tabKey: 0 });
    }, [])

    const fetchDataContent = (params: any, isSwitch?: boolean) => {
        let k = params?.tabKey ?? tabKey;
        let p = (data[k]?.page ?? 0) + 1;
        if (isSwitch && Array.isArray(data[k]?.data) && data[k].data!.length > 0) return;
        fetchData({ type: k + 1, page: p, limit: 5, ...params }).then(res => {
            data[k].page = p;
            data[k].isLoaded = res.data.length < 5;
            data[k].data = (data[k].data ?? []).concat(res.data);
            setData([...data]);
        })
    }
    const onSwitchTabKey = (key: number) => {
        fetchDataContent({ tabKey: key }, true);
        setTabKey(key);
    }
    const onViewMore = () => {
        fetchDataContent({});
    }
    const onRedirect = (id: string | number) => {
        let width = viewPortWidth();
        if (width <= 768) {
            window.location.href = `/news/detail/${id}`;
        }
    }
    return (<div className="news-page">
        <HeadWrapper
            title={<CellTitleHead title="新闻中心" english="NEWS CENTER" className='d-flex align-items-center justify-content-center news-page-content' />
            } imgUrl={imgNewsBg}>
            <Tab onChange={onSwitchTabKey}
                data={[{ key: 0, title: '热门活动' }, { key: 1, title: '公司动态' }, { key: 2, title: '行业发展' }, { key: 3, title: '国家政策' }]}
                activeKey={tabKey} className="mt-2 mt-md-0" />
            <div className='news-page-detail'>
                {
                    data[tabKey]?.data?.map?.((item: any) => (<CarouselContent onClick={() => onRedirect(item.id)} linkUrl={`/news/detail/${item.id}`} contentLine={2} className='news-page-card' url={item.picList[0]} data={item} key={item.id} />))
                }
                {
                    data[tabKey]?.isLoaded !== true && (<div className='news-page-detail__btn'>
                        <button onClick={onViewMore}>查看更多</button>
                    </div>)
                }
            </div>
        </HeadWrapper>
        {/* <div className="news-page-content">
          
        </div>
       */}
    </div>)
}

export default News;