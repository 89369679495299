import { CellTitleHead } from "../../components/CellTitle";
import HeadWrapper from "../../components/HeadWrapper";
import "./index.scss";
import imgNewsBg from "../../assets/caseSharing/img_case_banner.png";
import classNames from "classnames";
function CaseSharing() {
  return (
    <div className="case-page">
      <HeadWrapper
        mode={2}
        className='case-page-head'
        desc='鲸充新能源支持多种合作模式，公交车合作、出租车合作之外，我们还和商场私家车、空地停车场、物流车网约车、小区家用车等建立多种合作模式，最大化便捷新能源车汽车使用者的充电体验，助力智慧城市的建设发展。'
        title={
          <CellTitleHead
            title="经典案例"
            english="CLASSIC CASE"
            className="d-flex align-items-center justify-content-center news-page-content"
          />
        }
        imgUrl={imgNewsBg}
      >
        <section className="d-none d-md-block case-page-content">
          <img
            src="/image/caseSharing/img_case_pc1.png"
            className="d-block mt-4"
            alt=""
          />
          <img
            src="/image/caseSharing/img_case_pc2.png"
            className="d-block mt-4 mb-4"
            alt=""
          />
        </section>
        <section className="d-block d-md-none case-page-content  mt-2">
          <div className="flex-2">
            <img
              src="/image/caseSharing/img_case-1.png"
              className="d-block"
              alt=""
            />
            <img
              src="/image/caseSharing/img_case-2.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-1">
            <img
              src="/image/caseSharing/img_case-3.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-3">
            <div>
              <img
                src="/image/caseSharing/img_case-4.png"
                className="d-block"
                alt=""
              />
              <img
                src="/image/caseSharing/img_case-5.png"
                className="d-block"
                alt=""
              />
            </div>
            <img
              src="/image/caseSharing/img_case-6.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-2">
            <img
              src="/image/caseSharing/img_case-7.png"
              className="d-block"
              alt=""
            />
            <img
              src="/image/caseSharing/img_case-8.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-1">
            <img
              src="/image/caseSharing/img_case-9.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-2">
            <img
              src="/image/caseSharing/img_case-10.png"
              className="d-block"
              alt=""
            />
            <img
              src="/image/caseSharing/img_case-11.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-1">
            <img
              src="/image/caseSharing/img_case-12.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-2">
            <img
              src="/image/caseSharing/img_case-13.png"
              className="d-block"
              alt=""
            />
            <img
              src="/image/caseSharing/img_case-14.png"
              className="d-block"
              alt=""
            />
          </div>
          <div className="flex-2">
            <img
              src="/image/caseSharing/img_case-15.png"
              className="d-block"
              alt=""
            />
            <img
              src="/image/caseSharing/img_case-16.png"
              className="d-block"
              alt=""
            />
          </div>
        </section>
      </HeadWrapper>
    </div>
  );
}
export default CaseSharing;
