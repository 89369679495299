import $ from 'jquery';
import { useState } from 'react';
import { Toast, ToastBody } from 'react-bootstrap';
import { httpPost } from '../../services/request';
import classNames from 'classnames';
import "./index.scss";

function ContactChild(props: any) {
    const { className, style } = props;
    return (<div className={className} style={style}>
        <div className='row mx-0'>
            <div className='col-auto p-0 d-flex align-items-center'>
                <img className='ft-icon' src='/image/foot/img_phone.png' alt='' />
            </div>
            <div className='col ps-3 d-flex align-items-center justify-content-start'>
                <div className="ft-12 order-1 order-lg-2 ftcolor-minor ftheight-2">联系电话：400 110 1578</div>
            </div>
        </div>
        <div className='row mx-0 my-3'>
            <div className='col-auto p-0 d-flex align-items-center'>
                <img className='ft-icon' src='/image/foot/img_location.png' alt='' />
            </div>
            <div className='col ps-3 pe-0 d-flex justify-content-start  align-items-center'>
                <div className="ft-12 ftcolor-minor">地址：南京市江宁区南京南站南广场城际空间站H2-1201</div>
            </div>
        </div>
        <div className='row mx-0'>
            <div className='col-auto p-0 d-flex align-items-center'>
                <img className='ft-icon' src='/image/foot/img_email.png' alt='' />
            </div>
            <div className='col ps-3 d-flex align-items-center justify-content-start'>
                <div className="ft-12 ftcolor-minor">联系邮箱：service@whalecharge.com</div>
            </div>
        </div>
    </div>);
}

const TypeStates: any = {
    1: 'warning',
    2: 'success',
    3: 'danger'
}
function Footer(props: any) {
    const { className, isBasic } = props;
    const [toastShow, setToastShow] = useState<any>({ show: false, type: 1, msg: '' })
    const onSubmit = (e: any) => {
        let dataForm = $("#footer-form").serializeArray();
        let data = dataForm.map((item) => ({ [item.name]: item.value })).reduce((res, cur) => Object.assign(res, cur), {});
        if (!data.name || /^\s+$/.test(data.name)) {
            setToastShow({ show: true, type: 1, msg: '请输入姓名!' })
        }
        else if (!data.phone || /^\s+$/.test(data.phone)) {
            setToastShow({ show: true, type: 1, msg: '请输入手机号!' })
        }
        else if (!/^1[3456789]\d{9}$/.test(data.phone)) {
            setToastShow({ show: true, type: 1, msg: '请输入正确的手机格式!' })
        }
        else if (!data.content || /^\s+$/.test(data.content)) {
            setToastShow({ show: true, type: 1, msg: '请输入留言!' })
        }
        else {
            httpPost('api-cms/open/gw_open/add_message', data).then((response) => {
                if (response?.code === 0) {
                    setToastShow({ show: true, type: 2, msg: '留言成功!' })
                }
                else {
                    setToastShow({ show: true, type: 3, msg: '留言失败!' })
                }
            })
        }
        e.preventDefault();
    }
    const onRedirectRemark=()=>{
        window.open("https://beian.miit.gov.cn/#/Integrated/index","_blank");
    }
    return (
        <div className={classNames('foot',{"foot-dark":isBasic})}>
            <div className={`row m-0 text-start bg-black85 py-4 py-lg-5 ${className ? className : 'mt-0'}`}>
                <div className='col-auto px-0 order-lg-1 pe-lg-5 d-none d-lg-flex align-items-center'>
                    <img src='/image/foot/img_logo.png' className='ft-logo' alt='' />
                </div>
                <ContactChild className='col-auto order-3 order-lg-2 d-none d-lg-flex p-0 flex-column justify-content-between' />
                <div className='col p-0 ps-lg-4 d-flex order-2 order-lg-2 align-items-center'>
                    <form style={{ width: '100%' }} id='footer-form' onSubmit={onSubmit} autoComplete='off'>
                        <div className='row'>
                            <div className='col-12 col-lg-4'>
                                <div><input className='form-input' name="name" placeholder='请输入姓名' /></div>
                                <div className='py-2 py-lg-2'><input name="phone" className='form-input' placeholder="请输入电话" /></div>
                            </div>
                            <div className='col-12 pb-lg-2 col-lg-8'>
                                {/* @ts-ignore */}
                                <textarea name='content' rows="4" placeholder='在线留言' className='form-textarea' />
                            </div>
                            <div className='text-center text-lg-start foot-btn pt-2 pt-lg-0'>
                                <button type='submit' className='btn'>&emsp;提交&emsp;</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='d-block d-lg-none px-0'>
                    <ContactChild className='mb-4 my-lg-4 my-lg-0 mx-0' />
                </div>
            </div>
            <div className='py-2 ftcolor-white foot-copyright d-none d-md-block'>
                COPYRIGHT ©{new Date().getFullYear()} 鲸充新能源科技有限公司&nbsp;&nbsp;|&nbsp;&nbsp;营业执照&nbsp;&nbsp;|&nbsp;&nbsp;<span onClick={onRedirectRemark} className='remark-register'>苏ICP备2023010274号-1</span>
            </div>
            <div className='py-3 ftcolor-white foot-copyright d-block d-md-none'>
                COPYRIGHT ©{new Date().getFullYear()} 鲸充新能源科技有限公司<br />营业执照&nbsp;&nbsp;|&nbsp;&nbsp;<span className='remark-register'>苏ICP备2023010274号-1</span>
            </div>
            <Toast className={toastShow.type === undefined ? 'd-none' : ''} onClose={() => { setToastShow({ show: false, type: undefined, msg: '' }) }} delay={2000} autohide show={toastShow.show} bg={TypeStates[toastShow.type ?? "5"]}>
                <Toast.Header>
                    <strong style={{ color: toastShow.type === 1 ? "#333" : "#999" }} className="me-auto">{toastShow.type == 1 ? "警告提示" : "成功提示"}</strong>
                </Toast.Header>
                <ToastBody>
                    <div style={{ color: toastShow.type === 1 ? "#333" : "#333" }}>
                        {toastShow.msg}
                    </div>
                </ToastBody>
            </Toast>
        </div>
    )
}

export default Footer;