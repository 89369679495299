import { useState } from 'react';
import { CellTitleHead, CellTitleLine } from '../../../components/CellTitle';
import NavLink from '../../../components/NavLink';
import Tab from '../../../components/Tab';
import HeadWrapper from '../../../components/HeadWrapper';
import ProductBgImg from "../../../assets/products/img_products-bg.png";
import "./index.scss";
import DataSource from './data.json';

function ProductCard({ item, to }: { item: any, to: string }) {
    const onRedirect = () => {
        window.location.href = to;
    }
    return (<div className='product-card-wrapper'>
        <div className='product-card' onClick={onRedirect}>
            <div className='product-card-left'>
                <div className='product-card-title bl-1'>{item.title}</div>
                {
                    item.tags ? (<div className='product-card-tag'>
                        {
                            item.tags.map((t: string, index: number) => (<div key={index}>{t}</div>))
                        }
                    </div>) : (<div className='product-card-des bl-3'>{item.des}</div>)
                }
                <div className='d-none d-md-block'>
                    <div className='product-card-btn'><NavLink to={to}>了解更多</NavLink></div>
                </div>
            </div>
            <div>
                <div className='product-card-img__wrapper d-none d-md-flex'>
                    <img src={item.imgUrl} />
                </div>
                <div className='product-card-img__wrapper d-block d-md-none' style={{ background: `url(${item.imgUrl})` }} />
            </div>
        </div>
        {/* <div onClick = {onRedirect} className='product-card-position d-block d-md-none' /> */}
    </div>)
}

function ProductList() {
    const [tabKey, setTabKey] = useState(0);
    const [expand1, setExpand1] = useState(true);
    const [expand2, setExpand2] = useState(true);
    const [expand3, setExpand3] = useState(true);
    const [expand4, setExpand4] = useState(true);
    const [expand5, setExpand5] = useState(true);

    return (<div className="product-page">
        <HeadWrapper imgUrl={ProductBgImg} title={<CellTitleHead title="主营产品" english="MAIN PRODUCTS" className='d-flex align-items-center' />
        }>          <>

                <Tab onChange={(key) => { setTabKey(key) }} data={[{ key: 0, title: '国标交、直流桩/堆' }, { key: 1, title: '海外交、直流桩/堆' }]} activeKey={tabKey} />
                {
                    tabKey === 0 && (<div className='product-page-detail'>
                        <CellTitleLine caretDirection={expand1 ? "down" : "up"} onClick={() => { setExpand1(!expand1) }} caret title='交流桩' lineDirection='left' size='middle' />
                        <div className={expand1 ? "jc-expand" : "jc-collpase"}>
                            {
                                DataSource.domestic.exchange.map((item: any, index: number) => <ProductCard to={`/product/detail/${item.id}`} key={index} item={item} />)
                            }
                        </div>
                        <CellTitleLine caretDirection={expand2 ? "down" : "up"} onClick={() => { setExpand2(!expand2) }} caret title='直流桩' lineDirection='left' size='middle' />
                        <div className={expand2 ? "jc-expand" : "jc-collpase"}>
                            {
                                DataSource.domestic.current.map((item: any, index: number) => <ProductCard to={`/product/detail/${item.id}`} key={index} item={item} />)
                            }
                        </div>
                        <CellTitleLine caretDirection={expand3 ? "down" : "up"} onClick={() => { setExpand3(!expand3) }} caret title='液冷终端' lineDirection='left' size='middle' />
                        <div className={expand3 ? "jc-expand" : "jc-collpase"}>
                            {
                                DataSource.domestic.cold.map((item: any, index: number) => <ProductCard to={`/product/detail/${item.id}`} key={index} item={item} />)
                            }
                        </div>
                    </div>)
                }
                {
                    tabKey === 1 && (
                        <div className='product-page-detail'>
                            <CellTitleLine caretDirection={expand4 ? "down" : "up"} onClick={() => { setExpand4(!expand4) }} caret title='交流桩' lineDirection='left' size='middle' />
                            <div className={expand4 ? "jc-expand" : "jc-collpase"}>
                                {
                                    DataSource.foreign.exchange.map((item: any, index: number) => <ProductCard to={`/product/detail/${item.id}`} key={index} item={item} />)
                                }
                            </div>
                            <CellTitleLine caretDirection={expand5 ? "down" : "up"} onClick={() => { setExpand5(!expand5) }} caret title='直流桩' lineDirection='left' size='middle' />
                            <div className={expand5 ? "jc-expand" : "jc-collpase"}>
                                {
                                    DataSource.foreign.current.map((item: any, index: number) => <ProductCard to={`/product/detail/${item.id}`} key={index} item={item} />)
                                }
                            </div>
                        </div>
                    )
                }
                <div className='pb-3' />
            </>

        </HeadWrapper>

    </div>)
}

export default ProductList;